@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-color: red;
}


#card {
 box-shadow: inset 2px 2px 8px 7px rgba(0, 0, 0, 0.404);
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #1a1a1a;
}
::-webkit-scrollbar-thumb {
  background-color: #ffffff81;
  border-radius: 0.5rem;
}
body {
  background-color: black;
  scrollbar-color: black;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#booking-form-outer, .form-background {
  -webkit-box-shadow: 6px 6px 36px 12px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 6px 6px 36px 12px rgba(255, 255, 255, 0.1);
  box-shadow: 3px 2px 18px 1px rgba(75, 74, 74, 0.1);
  border: 1px solid rgba(75, 74, 74, 0.2);
  background: rgb(0, 0, 0);
  background: linear-gradient(
    square,
    rgba(0, 0, 0, 1) 92%,
    rgba(31, 31, 31, 1) 100%,
    rgba(0, 0, 0, 1) 92%,
    rgba(31, 31, 31, 1) 100%
  );
}


input {
  color-scheme: dark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#snail {
  animation: 0.8s ease-in-out 0s 1 slideInFromLeft;



}

nav {
  animation: 0.8s ease-in-out 0s 1 slideInFromRight;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  90% {
    transform: translateX(4%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0%);
  }
}
.accordion-container {
  position: relative;
  display: flex;
  flex-direction: row;

  margin: auto;
}

#header {
  background-image: url("https://iuqjmypymqoxzqdtxjif.supabase.co/storage/v1/object/public/images/eveningmuse/eveningmuseGroupDownsized.jpg");
  background-position: 0% 80%; ;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion-img {
  width: 20%;
  height: 600px;
  flex-grow: 0;
  flex-shrink: 1;
  transition: all 1s ease-in-out;
  flex-basis: 20%;
}
.accordion-img:hover {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
}

#acc-img-1 {
  background-image: url("https://iuqjmypymqoxzqdtxjif.supabase.co/storage/v1/object/public/images/eveningmuse/IMG_1200.JPG?t=2024-02-11T18%3A07%3A45.788Z");
  background-position: 48% 25%;
  background-size: cover;
  background-repeat: no-repeat;
}
#acc-img-2 {
  background-image: url("https://iuqjmypymqoxzqdtxjif.supabase.co/storage/v1/object/public/images/eveningmuse/monMuseNew.jpg?t=2024-02-21T16%3A54%3A11.974Z");
  background-position: 48% 25%; ;
  background-size: cover;
  background-repeat: no-repeat;
}
#acc-img-3 {
  background-image: url("https://iuqjmypymqoxzqdtxjif.supabase.co/storage/v1/object/public/images/eveningmuse/joemuse1New.jpg?t=2024-02-21T16%3A56%3A09.948Z");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
#acc-img-4 {
  background-image: url("https://iuqjmypymqoxzqdtxjif.supabase.co/storage/v1/object/public/images/eveningmuse/macMuse1.jpg?t=2024-02-21T16%3A55%3A14.730Z");
  background-position: 100% 40%;
  background-size: cover;
  background-repeat: no-repeat;
}
#acc-img-5 {
  background-image: url("https://iuqjmypymqoxzqdtxjif.supabase.co/storage/v1/object/public/images/eveningmuse/weirMuseNew.jpg?t=2024-02-21T16%3A53%3A13.123Z");
  background-position: 48% 25%;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion-container > div {
  filter: grayscale();
}
.accordion-container > div:hover {
  filter: none;
}

#bio-1 {
  text-indent: 12px;
  line-height: 30px;
  letter-spacing: 1.5px;
  margin-top: 10px;
}

#bio-2 {
  
  line-height: 30px;
  letter-spacing: 1.5px;
  margin-bottom: 10px;
}

#video {
  aspect-ratio: 16 / 9;
  width: 100%;
}


@media (max-width: 400px) {
  .accordion-container {
    flex-direction: column;
  }
  .accordion-img {
    display: none;
  }

  .accordion-img:hover {
    display: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  #insta-icon:hover {
    animation: insta-icon-spin 1 0.3s ease-in-out;
  }
}

@keyframes insta-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
